.outer-layer{
    position: relative;
    height: calc(100vh);
}

.inner-layer{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalformloaderstyle .modal-content{
    background-color: #fff0 !important;
    box-shadow: none !important;
}
