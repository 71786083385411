@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
.read{
    width: 15px;
    height: 15px;
    background: #6B6AE7;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.unread{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 12px;
    border: 2px solid black;
}

.up_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-bottom: solid 5px #EA0101;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}
.error{
    color: red;
}

.down_arrow{
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    border-top: solid 5px #02bf42;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}


.card{
   /* padding : 22px;        */
   padding:10px
}


.react-bootstrap-table thead{
    background-color: #2FC3B9;
}

.react-bootstrap-table thead tr th{
    color:white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.react-bootstrap-table tbody{
   border: 0.5px solid #ebe3e3;
}
.react-bootstrap-table{
    margin-bottom: 15px;
}

div.ag-header-row{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #2FC3B9;
    color: white !important;
}

.ag-theme-alpine .ag-root-wrapper{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 1px 6px 1px 1px #ede9e9;
}

.ag-theme-alpine .ag-header{
    height: 65px;
    min-height: 65px;
}

.ag-header-row{
    height: 76px;
}

.ag-react-container div button:first-child{
    padding-top: 6px;
}

.ag-react-container div button:nth-child(2){
    margin-bottom: 10px;
}


/* // toggle button */
.custom-toggle input:checked + .custom-toggle-slider:before{
    background-color: white;
}

.custom-toggle input:checked + .custom-toggle-slider{
    background-color: #02bf42; 
    border:#02bf42
}


.ag-header-container{
    background-color: #6E68E7;
}

.ag-header-cell-text{
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
}
.ag-icon-filter{
    color: #fff;
}

.all_btn{
    width: 126px;
    height: 36px;
    left: 258px;
    top: 180px;
    background: #6B6AE7;
    border-radius: 8px 0px 0 8px;
    padding: 7px 50px;
    cursor: pointer;
    /* position: fixed; */
}

.all_txt{
    /* position: absolute; */
width: 29px;
height: 28px;
left: 307px;
top: 184px;

font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 35px;

/* color: #FFFFFF; */
}

.new_btn{
    width: 126px;
    /* position: fixed; */
    padding: 7px 50px;
    height: 36px;
    left: 436px;
    top: 181px;
    background: #D1D4F7;
    cursor: pointer;
}

.fav_btn{
    cursor: pointer;
    padding: 7px 50px;
    /* position: fixed; */
    width: 126px;
    height: 36px;
    left: 561px;
    top: 181px;
    background: #D1D4F7;
    border-radius: 0px 8px 8px 0px;
}

#primary{
    color: #fff;
    background: #6b6ae7;
}

#secondary{
    color: #000;
    background: #D1D4F7;
}


/* navbar main */
/* .navbar-top{
    background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%);

 
} */
.auth{
    background: inherit !important;
}
/* header */
.header{
    background: #fff !important;
}
.mb-0{
    color:#3F3F3F;
}

/* filter input */
.ag-input-wrapper{
    color:grey;
}


/* profile page */
.bg-gradient-info{
    background: linear-gradient(359deg, #efd9e5 0, #dccff0 100%) !important;
}

.pagination{
    flex-wrap: wrap !important;
}

#sidenav-main {
    /* background: linear-gradient(180deg, #fae1de 10%, #d8ccf1 100%); */
    background: #fff;
}

#sidenav-main h1 {

/* font-family: 'Heebo'; */
font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 53px;
/* identical to box height */
margin-bottom: 40%;
margin-top: 30%;
color: #024554;

}
.dash-button {
    color: #fff;
    background: #05A8A8;
    border: #05A8A8;
    border-radius:25px;

}
.phase-button {
    background: #fff;
    color: #3F3F3F;
    border: 1px solid #03A6A6;
    border-radius:25px;

}
.am-link{
    color:#05A8A8;
    text-align:left;
    padding-left: 0px;


line-height: 22px;
/* identical to box height */

text-decoration-line: underline;
}
.dash-analy{
    background: #F4F4F4;
border-radius: 8px;
padding: 1%;
/* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.dash-col-analy,.dash-col-analy-3 .dash-inner-row {
    background: #FFFFFF;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 16px;
max-width: 100%;
margin:1%;

}
.clientformtag{
    text-align: left;
}
.dash-col-analy .active {
    background-color: #77fff81f !important;
}
.dash-col-analy{
    flex :0 0 19.66667% !important;
    max-width:  18%;
}
.dash-col-analy-3{
    flex :0 0 24.66667% !important;
    max-width:  25%;
}
.dash-inner-row{
    padding: 6%;
    /* width: 320px !important; */
}
.text-dash{
    color:#3F3F3F;
    font-size: 12px;
}
.dash-inner h1 {
    font-size: 32px;
}
.dash-inner h2 {
    font-size: 20px;
}
/* .card-header{
    padding: 0;
} */
.main-content{
    background: #ffff;
}
.sortclass{
    cursor: pointer;
}
.navbar-search .input-group{
    border: 0px;
}
.filter-button{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F;
    padding: 6px !important;
}
.filter-button on:hover{
    background: #F6F6F6 !important;
    border:#F6F6F6;
    border-radius:5px;
    color:#3F3F3F
}
.taskdetailmodal{
    max-width :80%
}
.whitespace{
    white-space :unset !important;
}
.nav-pills .nav-link.active{
    color:#5e72e4;
    border-bottom: 1px solid #5e72e4;
    background-color: #fff;
}
.nav-pills .nav-link{
    color : #4c4c4c;
}
 /* tr:nth-child(3n+2) {
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n+1) {
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
   } 
   tr:nth-child(3n) {
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
   }
    */
    .form-control:disabled, .form-control[readonly] {
        background-color: #ffffff;
        opacity: 1;
        /* color: #f5f3f9; */
    }
    .disablerow {
        color: #f5f3f9;
    }
    .edit-row span {
        padding: 10px;
        color:#878787;
        margin-right:25px;
        font-size: 14px;
    }
.navbar-top h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #444444;
}
.navbar-top .noti-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #F24C05;
    cursor: pointer;
}
.nav-link{
   color: #3F3F3F;
   /* margin-left: 6.3%; */
   font-weight: 600 !important;
   font-size: 16px !important;
   /* padding: 6% !important; */
   box-shadow: none !important;
 
}
.navbar-nav{
    margin-left:-0.5rem !important;
}

 .footer{
     background: #ffffff !important;
 }
 .bg-gradient-default {
    background: linear-gradient(87deg,  #5d6ea8 0, #3fbce5 100%) !important;
}
.pb-8, .py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.timeline {
    position: relative;
}
.timeline-one-side .timeline-step, .timeline-one-side:before {
    left: 1rem;
}
[data-timeline-axis-style=dashed]:before {
    border-right-style: dashed!important;
}
.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px solid #e9ecef;
}
.timeline-content {
    margin-left: 60px;
    padding-top: 0.5rem;
    position: relative;
    top: -6px;
}
.timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
}
.timeline-block:first-child {
    margin-top: 0;
}

.timeline-block {
    position: relative;
    margin: 2em 0;
}
.navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
    background-color: #FFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-left: none;
    margin-right: 15px;
    color : #7A69F0;
}
.blue-row{
    background: #EDF7FF; 
    border-left: 7px solid #0060B8;
    border-radius: 4px 0px 0px 4px;

}
.orange-row{
    background: #FFF9F2;
    border-left: 7px solid #F28705;
    border-radius: 4px 0px 0px 4px;
}
.purple-row{
    background: #FEF5FF;
    border-left: 7px solid #D557EA;
    border-radius: 4px 0px 0px 4px;
}
.table-responsive{
    border-radius : 16px;
    border: 1px solid #D9D9D9;
}
.table .thead-light th {
    text-transform :none;
    background-color: #e6e3f8;
    color:#666666;
/* border: 2px solid #FFFFFF; */
font-size: 14px;
}
.table tr:nth-child(even) {
    background-color: #F4F4F4;
}
.table th, .table td{
    padding:10px;
}
.table td,th {
border: 2px solid #FFFFFF;
}
.notify-footer{
   background-color: #21c1c7;
   padding: 20px;
}

.notify-footer span{
   color:white;
   font-size: 18px;
   cursor: pointer; 
}

.notify-header{
   display: block;
   border-bottom: 1px solid #e9e9e9;
   /* border-bottom: 1px solid grey;    */
}
.header-right-el{
    color: #21c1c7;
    font-size: 20px;
}
.header-right-el span{
   cursor: pointer;  
}

.header-right-el span:nth-child(2){
    margin-left: 50px;     
}

.btn-comment{
    background-color: #6b6ae7; 
    color: #e9e9e9; 
}

.btn-status{
    background-color: darkorange;
    color: #e9e9e9; 
}

.btn-c-style{
    width:160px;
}

.notify-count{
    border: 1px solid #cd2e2e;
    position: absolute;
    top: -11px;
    right: -4px;
    padding-top: 6px;
    text-align: center;
    background-color: #cd2e2e;
    color: white;
    font-size: 13px;
    width: 28px;
    border-radius: 50px;
    height: 28px;  
}
.filter{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control:disabled{
    background-color: #f1f1f16e;  
}
.btn-icon-clipboard{
    padding: 10px 20px 10px 20px;
    background-color: #F4F4F4;
    font-family: 'Inter';
}

.report-view-btn{
    border: 1px solid #2fc3b9;
    background:none;
    color: #2fc3b9;
    border-radius:18px;
    padding: 4px 16px;
    transition: all 0.25s;     
}

.report-view-btn:hover{
    border: 1px solid #2fc3b9;
    background: #2fc3b9;
    color: #ffffff;
    box-shadow: 0px 2px 10px 0px hsl(0deg 0% 15% / 29%);  
    transition: all 0.25s;
}

.custom-style tbody tr{
    /* box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 20%); */
    transition: all 0.25s;
 }

.custom-style tbody tr:hover{
   box-shadow: inset 0px -3px 4px 0px hsl(0deg 0% 15% / 8%);
   transition: all 0.25s;
}

.custom-style thead th{
   padding: 16px 10px !important; 
}

.custom-style tbody td{
    padding: 16px 10px !important;
}

.custom-style tbody tr td span.complete,span.allinforeceived,span.missinginfobutjobcanbestarted{
  color:#09a8a9;   
}

.custom-style tbody tr td span.inprogress{
  color:#fea732;   
}

.custom-style tbody tr td span.onhold,span.missinginfo{
    color:#ff1717;   
 }


@media (min-width: 768px){
.navbar-vertical .navbar-collapse:before {
    content: '';
    display: block;
    margin: 0rem -1rem;
}

.sider-logo{
    /* background: linear-gradient(360deg, #88FDDE  0%, #08A9A8 80%) !important; */
    margin-left: -37px;
    margin-top: -26px;
    line-height: 4;
    margin-right: -24px!important;
}
.theme-logo{
    margin-top: 18px;
}
.add-btn-ul{
    position: absolute;
    left: 2%;
    right: 76%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
.edit-btn-ul{
    position: absolute;
    left: 2%;
    right: 82%;
    top: 79.49%;
    bottom: 0%;
    background: #6E68E7;
}
}

@media only screen and (max-width: 600px) {
    .notify-container {
      display: none;
    }
    #navbar-main{
        display: none;
    }
    .all_btn{
        width: 126px;
        height: 36px;
        left: 258px;
        top: 180px;
        background: #6B6AE7;
        border-radius: 8px 0px 0 8px;
        padding: 7px 30px;
        cursor: pointer;
        /* position: fixed; */
    }
    
    
    .new_btn{
        width: 126px;
        /* position: fixed; */
        padding: 7px 30px;
        height: 36px;
        left: 436px;
        top: 181px;
        background: #D1D4F7;
        cursor: pointer;
    }
    
    .fav_btn{
        cursor: pointer;
        padding: 7px 30px;
        /* position: fixed; */
        width: 126px;
        height: 36px;
        left: 561px;
        top: 181px;
        background: #D1D4F7;
        border-radius: 0px 8px 8px 0px;
    }
  }


  .browse-icon{
     font-size: 5.4rem;
     color: #2fc3b9;
  }
  .browse-title{
    font-size: 1.5rem;
  }
  .browse-btn{
    color: #ffffff !important;
    background-color: #2fc3b9 !important;
    font-weight: 450;
    font-size: 1.2rem;
  }

  .progress_css div{
    border-radius: 0px 0px 0px 0px !important;
  }
  
  .progress_css div div span{
    padding: 5px 16px !important;
  }

  .dz-ui-label{
    color: #2fc3b9 !important;
    font-family: inherit !important;
    font-size: 28px !important;
  }

  .dropzone-ui-extra {
    border: 1px dashed #d8d8df !important;  
  }

  .bg-default{
    background: linear-gradient(#fae1de, #d8ccf1) !important;
  }

  .btn-primary{
    background-color: #7a69f0;
    border-color: #7a69f0;
  }

  .main-content .navbar-top{
    width: 94% !important;
    border-bottom: 0.5px solid #e4e8ec;
    margin-left: 3%;
    padding: 3px;
  }
  .customprofile li{
    width : 100% !important;
  }
  .mt--7{
    background: #f5f3f9 !important;
  }
  .card-1{
    background: #d6ffff;
  }
  .card-2{
    background: #ffefef;
  }
  .card-3{
    background: #f2ffe7;
  }
  .card-4{
    background: #fbf2ef;
  }
  .card-5{
    background: #fffae2;
  }
  .card-6{
    background: #f2e8ff;
  }
  .card-body{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .rsw-editor {
    min-height: 250px;
}

