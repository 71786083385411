/* .tooltip-container {
  position: relative;
}

.tooltip-button {
  padding: 5px 8px;
  cursor: pointer;
  font-size: 12px;
  width: 30px;
  height: 30px;
}

.tooltip-button:hover {
  font-size: 13px; 
}


.custom-tooltip {
  position: absolute;
  bottom: calc(100% + 8px); 
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.3); 
  backdrop-filter: blur(10px); 
  color: rgba(0, 0, 0, 0.721); 
  border: 2px solid rgba(0, 0, 0, 0.134);
  border-radius: 5px;
  padding: 5px;
  opacity: 0;
  z-index: 9999;
  display: none;
  transition: opacity 0.3s ease-in-out; 
}

.custom-tooltip::before {
  content: "";
  position: absolute;
  bottom: -16px; 
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #00000098 transparent transparent transparent;
}


.tooltip-container:hover .custom-tooltip {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

---------- */
.spinner-loader {
  width: 25px;
  position: relative;
  height:25px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #2DCE89;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-top: 0.5rem;
}
.spinner-loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 1;
  top: 10;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-left: 4px solid #2DCE89;
  border-bottom: 4px solid transparent;
  animation: spinner-rotation 0.5s linear infinite reverse;
}
@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 